<template>
  <div>
    <v-bottom-navigation
        absolute
        hide-on-scroll
        horizontal
        dark
        scroll-target="#home">
      <h5 class="mt-4 body-2 grey--text text--darken-1">©2023
        <a style="text-decoration: underline;" @click="redirect">Amarildo Gjeçaj</a>
        | All rights reserved</h5>

    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: "About",
  methods: {
    redirect() {
      window.open("https://www.amarildo.xyz", '_blank').focus();
    }
  }
}
</script>